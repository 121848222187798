const toggleCollapse = (element: HTMLElement) => {
  const collapsed = element.getAttribute('data-collapsed') === 'true'
  element.setAttribute('data-collapsed', `${!collapsed}`)
  const existingTrackingAttr = element.getAttribute('data-tracking-element')!

  const jobId = element.getAttribute('data-jobid')
  if (jobId && collapsed) {
    window.location.hash = '#' + jobId
  }

  const changedTrackingAttr = existingTrackingAttr.includes('open')
    ? existingTrackingAttr.replace('open', 'close')
    : existingTrackingAttr.replace('close', 'open')
  element.setAttribute('data-tracking-element', changedTrackingAttr)
}

const activateCollapseByHash = () => {
  const urlHash = window.location.hash.substr(1)
  if (urlHash) {
    const anchor = document.querySelector(`.Collapse a[name="${urlHash}"]`)
    const collapseElem = anchor
      ?.closest('.Collapse')
      ?.querySelector('[data-collapsed="true"]') as HTMLElement

    if (collapseElem) {
      toggleCollapse(collapseElem)
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  const collapseTriggers = document.querySelectorAll('[data-collapsed]')!

  collapseTriggers.forEach((trigger) => {
    trigger.addEventListener('click', () => {
      toggleCollapse(trigger as HTMLElement)
    })
    trigger.addEventListener('keyup', (event) => {
      const keyboardEvent = <KeyboardEvent> event;
      if (keyboardEvent.key === 'Enter') {
        event.preventDefault()
        toggleCollapse(trigger as HTMLElement)
      }
    })
  })

  activateCollapseByHash()
})

window.addEventListener('hashchange', activateCollapseByHash)
